var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-card', {
    staticClass: "withdrawal"
  }, [_c('v-card-title', {
    staticClass: "justify-center font-size-24 font-weight-medium px-16px py-18px"
  }, [_vm._v("온 구로 앱 회원 탈퇴 안내 ")]), _c('v-card-text', {
    staticClass: "px-16px pb-0"
  }, [_c('v-sheet', {
    staticClass: "px-16px py-40px mb-16px",
    attrs: {
      "color": "#E1F9F1",
      "rounded": "lg"
    }
  }, [_c('h2', {
    staticClass: "font-size-20 text-center mb-24px",
    staticStyle: {
      "color": "#06c681"
    }
  }, [_vm._v(" 회원 정보를 입력 후 "), _c('br'), _vm._v(" 탈퇴 버튼을 눌러주세요 ")]), _c('p', {
    staticClass: "font-size-16 font-weight-medium text-center grey--text mb-8px"
  }, [_vm._v(" 온 구로 앱 회원 탈퇴 시 온 구로 앱에서 "), _c('br'), _vm._v(" 이용 중인 정보가 모두 삭제됩니다. ")]), _c('p', {
    staticClass: "font-size-16 font-weight-bold text-center"
  }, [_vm._v("온 구로 앱을 이용해 주셔서 감사합니다.")])]), _c('u-text-field-underlined', {
    attrs: {
      "label": "이름",
      "placeholder": "이름을 입력하세요.",
      "clearable": ""
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('u-text-field-underlined', {
    attrs: {
      "label": "전화번호",
      "placeholder": "전화번호를 입력하세요.",
      "clearable": ""
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "px-16px pt-60px pb-16px"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "#FF7979"
    },
    on: {
      "click": _vm.withdraw
    }
  }, [_c('span', {
    staticClass: "white--text font-size-16"
  }, [_vm._v("회원탈퇴")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }