<template>
    <v-app>
        <v-card class="withdrawal">
            <v-card-title class="justify-center font-size-24 font-weight-medium px-16px py-18px">온 구로 앱 회원 탈퇴 안내 </v-card-title>
            <v-card-text class="px-16px pb-0">
                <v-sheet color="#E1F9F1" rounded="lg" class="px-16px py-40px mb-16px">
                    <h2 class="font-size-20 text-center mb-24px" style="color: #06c681">
                        회원 정보를 입력 후 <br />
                        탈퇴 버튼을 눌러주세요
                    </h2>
                    <p class="font-size-16 font-weight-medium text-center grey--text mb-8px">
                        온 구로 앱 회원 탈퇴 시 온 구로 앱에서 <br />
                        이용 중인 정보가 모두 삭제됩니다.
                    </p>
                    <p class="font-size-16 font-weight-bold text-center">온 구로 앱을 이용해 주셔서 감사합니다.</p>
                </v-sheet>

                <u-text-field-underlined v-model="username" label="이름" placeholder="이름을 입력하세요." clearable></u-text-field-underlined>

                <u-text-field-underlined v-model="phone" label="전화번호" placeholder="전화번호를 입력하세요." clearable></u-text-field-underlined>
            </v-card-text>
            <v-card-actions class="px-16px pt-60px pb-16px">
                <v-btn block color="#FF7979" class="v-size--xx-large" @click="withdraw">
                    <span class="white--text font-size-16">회원탈퇴</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-app>
</template>

<script>
import api from "@/api";

import UTextFieldUnderlined from "@/components/publish/styles/forms/u-text-field-underlined.vue";

export default {
    components: {
        UTextFieldUnderlined,
    },
    data() {
        return {
            username: null,
            phone: null
        }
    },
    methods: {
        async withdraw() {
            if (this.validate()) {
                if (confirm("탈퇴를 진행하시겠습니까?")) {
                    await api.v1.users.withdraw({ username: this.username, phone: this.phone })
                    alert("탈퇴되었습니다")
                    this.username = null;
                    this.phone = null;
                }
            }
        },
        validate() {
            try {
                if (!this.username) throw new Error("이름을 입력해주세요")
                if (!this.phone) throw new Error("전화번호를 입력해주세요")
                return true;
            } catch (error) {
                alert(error.message)
                return false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.withdrawal {
    min-height: 100vh;
}
</style>